import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import getCsrfToken from '../helpers/csrf_token'

export default class extends Controller {
  connect() {
    this.renumber()
    const locked = this.element.hasAttribute("data-locked")
    if(!locked){
      this.sortable = Sortable.create(this.element, {
        group: 'shared',
        animation: 150,
        onEnd: this.end.bind(this),
        onMove: this.move.bind(this),
        handle: ".my-handle"
      })
    }
  }

  renumber() {
    document.querySelectorAll('.not-backups .categorizable-position-id').forEach((element, index) => { 
      element.innerText = `[${index + 1}]`
      element.classList.remove('d-none')
    })

    document.querySelectorAll('.backups .sortable-backup-label').forEach((element, index) => { 
      element.classList.remove('d-none')
    })

    document.querySelectorAll('.not-backups .sortable-backup-label').forEach((element, index) => { 
      element.classList.add('d-none')
    })

    document.querySelectorAll('.backups .categorizable-position-id').forEach((element, index) => { 
      element.classList.add('d-none')
    })
  }

  move(event) {
    const { to, target } = event
    const dropParamAttribute = to.getAttribute("data-drop-params")
    const additionalParams = dropParamAttribute ? JSON.parse(dropParamAttribute) : {}
    const isBackUp = additionalParams.backup
    const backUpCount = to.childElementCount;
    console.log(isBackUp)
    console.log(backUpCount)

    // debugger;

    if(isBackUp && backUpCount > 0) {
      return false
    }else{
      return true
    }
  }

  async end(event) {
    let id = event.item.dataset.id
    console.log({ id, event, newIndex: event.newIndex + 1 })
    const position = event.newIndex + 1
    const { to } = event
    const dropParamAttribute = to.getAttribute("data-drop-params")
    const additionalParams = dropParamAttribute ? JSON.parse(dropParamAttribute) : {}
    try {
      const url = this.data.get("url").replace(":id", id)
      await fetch(url, {
        body: JSON.stringify({
          position,
          ...additionalParams
        }),
        method: "PUT",
        credentials: "same-origin",
        headers: {
          "X-CSRF-Token": getCsrfToken(),
          "Content-Type": "application/json",
          "Accept": "application/json"
        }
      })
      this.renumber()

    }
    catch(e) {
      console.log(e)
      alert("Unable to update sort order. Please refresh the page and try again.")
    }
  }
}
