import { Controller } from "@hotwired/stimulus"
import TimelinesChart from 'timelines-chart'
import { addSeconds, addMilliseconds } from 'date-fns'
import noUiSlider from 'nouislider'
import 'nouislider/dist/nouislider.css'

export default class extends Controller {

  static targets = [ "video", "transcript", "segmentTimeline", "transcriptTimeline", "transcriptWord", "searchAndHighlightTranscriptField", "searchAndHideTranscriptField", "scrubber", "startTime", "endTime", "currentTime" ]
  static values = { segments: Array, excludedWords: Array, seek: Number }

  connect() {
    google.charts.load('current', {packages: ['timeline']});
    if(this.hasSegmentTimelineTarget) {
      google.charts.setOnLoadCallback(this.drawSegmentChart)
    }
    if(this.hasTranscriptTimelineTarget) {
      google.charts.setOnLoadCallback(this.drawTranscriptChart)
    }
    if(this.hasTranscriptTarget) {
      this.videoTarget.addEventListener('timeupdate', this.timeUpdate)
    }
    this.videoTarget.addEventListener('loadedmetadata', this.setupScrubber)
    this.searchAndHighlightTranscript()
    // this.searchAndHideTranscript()
    if(this.hasSeekValue) {
      this.videoTarget.currentTime = this.seekValue
    }

  }

  setupScrubber = () => {
    const videoLength = this.videoTarget.duration

    this.scrubber = noUiSlider.create(this.scrubberTarget, {
      start: [0, videoLength],
      connect: true,
      behaviour: 'tap-drag',
      range: {
        'min': 0,
        'max': videoLength
      }
    });
    this.scrubber.on('update', this.scrubberUpdate)
  }

  scrubberUpdate = (values, handle) => {
    this.startTimeTarget.value = values[0]
    this.endTimeTarget.value = values[1]

    const currentTime = this.videoTarget.currentTime
    if(currentTime < values[0]) {
      this.videoTarget.currentTime = values[0]
    }
    else if(currentTime > values[1]) {
      this.videoTarget.currentTime = values[1]
    }
  }

  seekTo = (event) => {
    event.preventDefault()
    const timestamp = event.target.closest('a').getAttribute("data-nitro-start-time")
    this.videoTarget.currentTime = timestamp
  }

  timeUpdate = (event) => {
    const timestamp = event.target.currentTime

    // Update the current time display
    this.currentTimeTarget.value = timestamp.toFixed(2)

    // Highlight the current word
    this.transcriptWordTargets.forEach(target => {
      const startTime = target.getAttribute("data-nitro-start-time")
      if (timestamp >= startTime) {
        target.classList.add("played")
      } else {
        target.classList.remove("played")
      }
    })

    // Pause if within 1 second of the end time
    const endTime = this.endTimeTarget.value
    if(endTime && timestamp >= parseFloat(endTime) && timestamp <= parseFloat(endTime) + 1) {
      this.videoTarget.pause()
    }
  }

  drawSegmentChart = () => {
    const now = new Date()
    const technicalSegmentData = this.segmentsValue
      .filter(segment => segment.type === 'TECHNICAL_CUE')
      .map(segment => ['Technical', segment.technical_cue_segment.type, addMilliseconds(now, segment.start_timestamp_millis), addMilliseconds(now, segment.end_timestamp_millis)])

    const shotSegmentData = this.segmentsValue
      .filter(segment => segment.type === 'SHOT')
      .map(segment => ['Shot', `Shot ${segment.shot_segment.index}`, addMilliseconds(now, segment.start_timestamp_millis), addMilliseconds(now, segment.end_timestamp_millis)])


    const chart = new google.visualization.Timeline(this.segmentTimelineTarget)
    const dataTable = new google.visualization.DataTable()
    dataTable.addColumn({ type: 'string', id: 'Position' });
    dataTable.addColumn({ type: 'string', id: 'Name' });
    dataTable.addColumn({ type: 'date', id: 'Start' });
    dataTable.addColumn({ type: 'date', id: 'End' });
    dataTable.addRows(technicalSegmentData)
    dataTable.addRows(shotSegmentData)
    chart.draw(dataTable);


    // Track when the user clicks on a timeline bar
    google.visualization.events.addListener(chart, 'select', (event) => {
      const selection = chart.getSelection()
      if (selection.length === 1) {
        const segment = dataTable.getValue(selection[0].row, 1)
        const startTime = dataTable.getValue(selection[0].row, 2)
        const endTime = dataTable.getValue(selection[0].row, 3)
        console.log({ startTime, endTime })
        const startOffset = (startTime - now) / 1000
        const endOffset = (endTime - now) / 1000
        this.videoTarget.currentTime = startOffset
        this.scrubber.set([startOffset, endOffset])
      }
    })
  }

  drawTranscriptChart = () => {
    const now = new Date()
    const transcriptData = new Map()

    this.transcriptWordTargets
      .filter(target => target.classList.contains("pronunciation"))
      .forEach(target => {
        const word = target.innerText.trim().toLowerCase()
        const wordlist = transcriptData.get(word) || []

        const startTime = target.getAttribute("data-nitro-start-time")
        const endTime = target.getAttribute("data-nitro-end-time")
        wordlist.push([word, "", addSeconds(now, startTime), addSeconds(now, endTime)])
        transcriptData.set(word, wordlist)
    })

    const chart = new google.visualization.Timeline(this.transcriptTimelineTarget)
    const dataTable = new google.visualization.DataTable()
    dataTable.addColumn({ type: 'string', id: 'Position' });
    dataTable.addColumn({ type: 'string', id: 'Name' });
    dataTable.addColumn({ type: 'date', id: 'Start' });
    dataTable.addColumn({ type: 'date', id: 'End' });

    Array.from(transcriptData)
      .filter(elem => !this.excludedWordsValue.includes(elem[0]))
      .map(elem => elem[1])
      .sort((a, b) => b.length - a.length)
      .forEach(value => dataTable.addRows(value))

    chart.draw(dataTable);


    // Track when the user clicks on a timeline bar
    google.visualization.events.addListener(chart, 'select', (event) => {
      const selection = chart.getSelection()
      if (selection.length === 1) {
        const segment = dataTable.getValue(selection[0].row, 1)
        const startTime = dataTable.getValue(selection[0].row, 2)
        const offset = (startTime - now) / 1000
        this.videoTarget.currentTime = offset
      }
    })
  }

  searchAndHideTranscript = (event) => {
    const value = this.searchAndHideTranscriptFieldTarget.value.toLowerCase()

    if(this.searchAndHideTimeout) {
      clearTimeout(this.searchAndHideTimeout)
    }

    this.searchAndHideTimeout = setTimeout(() => {
      if(value) {
        this.transcriptTarget.classList.add("search-and-hide")

        this.transcriptWordTargets.forEach(target => {
          if(target.innerText.toLowerCase().includes(value)) {
            target.classList.add("active")
          } else {
            target.classList.remove("active")
          }
        })
      }
      else {
        this.transcriptTarget.classList.remove("search-and-hide")
        this.transcriptTarget.querySelectorAll(".active").forEach(target => target.classList.remove("active"))
      }

    }, 500)
  }

  searchAndHighlightTranscript = (event) => {
    const value = this.searchAndHighlightTranscriptFieldTarget.value.toLowerCase()

    if(this.searchAndHighlightTimeout) {
      clearTimeout(this.searchAndHighlightTimeout)
    }

    this.searchAndHighlightTimeout = setTimeout(() => {
      if(value) {
        this.transcriptTarget.classList.add("search-and-highlight")

        this.transcriptWordTargets.forEach(target => {
          if(target.innerText.toLowerCase().includes(value)) {
            target.classList.add("active")
          } else {
            target.classList.remove("active")
          }
        })
      }
      else {
        this.transcriptTarget.classList.remove("search-and-highlight")
        this.transcriptTarget.querySelectorAll(".active").forEach(target => target.classList.remove("active"))
      }

    }, 500)
  }
}
