// https://github.com/KaneCohen/tokenfield

import { Controller } from "@hotwired/stimulus"
import Tokenfield from "tokenfield"
import "tokenfield/dist/tokenfield.css";

export default class extends Controller {
  static targets = [ "tokenfieldInput", "name", "description" ]
  static values = {
    clipId: Number,
  }

  connect() {
    let options = JSON.parse(this.tokenfieldInputTarget.getAttribute('data-options'))
    let exisitngItems = JSON.parse(this.tokenfieldInputTarget.getAttribute('data-set-items'))
    let remoteURL = this.tokenfieldInputTarget.getAttribute('data-remote-url')

    // console.log(remoteURL)

    this.tokenfield = new Tokenfield({
      el: this.tokenfieldInputTarget,
      setItems: exisitngItems,
      newItems: true,
      remote: {
        url: remoteURL,
      }
    });

  }

  async generate(e) {
    e.preventDefault()

    const name = this.nameTarget.value
    const description = this.descriptionTarget.value
    const clipId = this.clipIdValue
    const originalButtonContent = e.target.innerHTML
    e.target.disabled = true
    e.target.innerHTML = `<i class="fas fa-spinner fa-spin"></i> ${originalButtonContent}`

    const data = { name, description, clip_id: clipId }

    const csrfTokenSelected = document.querySelector("[name='csrf-token']")
    const csrfToken = csrfTokenSelected ? csrfTokenSelected.content : null
    await fetch('/tags/gpt_suggestions', {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(response => response.text())
      .then(text => JSON.parse(text))
      .then(json => {
        if(json.tags) {
          this.tokenfield.addItems(json.tags)
        }
        console.log({ json })
      })
      .catch(e => {
        alert("Error occurred fetching tags")
        console.warn(e.message)
      })
    
    e.target.disabled = false
    e.target.innerHTML = originalButtonContent
  }
}
