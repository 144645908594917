import { Controller } from "@hotwired/stimulus"
import Tokenfield from "tokenfield"
import "tokenfield/dist/tokenfield.css";

export default class extends Controller {
  static targets = [ "tokenfieldInput" ]

  connect() {
    const existingItems = JSON.parse(this.tokenfieldInputTarget.getAttribute('data-set-items'))
    const existingFieldName = this.tokenfieldInputTarget.getAttribute("data-existing-field-name")
    const newFieldName = this.tokenfieldInputTarget.getAttribute("data-new-field-name")
    const remoteURL = this.tokenfieldInputTarget.getAttribute('data-remote-url')
    console.log({ remoteURL })

    this.tokenfield = new Tokenfield({
      el: this.tokenfieldInputTarget,
      setItems: existingItems,
      itemName: existingFieldName,
      newItems: true,
      newItemName: newFieldName,
      remote: {
        url: remoteURL,
      }
    });

  }
}
