import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "alert" ]

  submitForm(event) {
    // event.preventDefault()

    const positionFields = event.target.querySelectorAll('.position-field')
    
    const values = Array.from(positionFields).map(field => field.value)
    // debugger;

    // console.log('form submitted');
  }

}