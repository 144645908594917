import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tag"]

  connect() {
    console.log('ehre !!')
    this.tagTarget.value = "fish"
    console.log('ehre23 !!')
  }
}

