import { Controller } from "@hotwired/stimulus"
import TimelinesChart from 'timelines-chart'
import { addSeconds, addMilliseconds } from 'date-fns'
import noUiSlider from 'nouislider'
import 'nouislider/dist/nouislider.css'

export default class extends Controller {
  static targets = [ "audio" ]

  connect() {
    this.audioTarget.addEventListener('timeupdate', this.timeUpdate)
  }

  play = (e) => {
    e.preventDefault()
    const start = e.target.getAttribute('data-start-time')
    const end = e.target.getAttribute('data-end-time')
    if(start && end) {
      this.audioTarget.currentTime = parseFloat(start)
      this.audioTarget.play()
      this.endTime = parseFloat(end)
    }
    else {
      this.audioTarget.play()
      this.endTime = null
    }
  }

  timeUpdate = (e) => {
    const timestamp = e.target.currentTime
    if(this.endTime && timestamp > this.endTime) {
      this.audioTarget.pause()
    }
  }
}
