import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "button", "formWrapper" ]

  refresh() {
    const form = this.formWrapperTarget.querySelector("form")
    const csrfTokenSelected = document.querySelector("[name='csrf-token']")
    const csrfToken = csrfTokenSelected ? csrfTokenSelected.content : null

    fetch('/clip_searches/refresh', {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": csrfToken,
      },
      body: new FormData(form)
    })
      .then(response => response.text())
      .then(html => {
        this.formWrapperTarget.innerHTML = html
      })
      .catch(e => {
        console.warn(e.message)
      })
  }


  addSearchFieldGroup(e) {
    e.preventDefault()

    const form = this.formWrapperTarget.querySelector("form")
    const csrfTokenSelected = document.querySelector("[name='csrf-token']")
    const csrfToken = csrfTokenSelected ? csrfTokenSelected.content : null

    fetch('/clip_searches/add_field_group', {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": csrfToken,
      },
      body: new FormData(form)
    })
      .then(response => response.text())
      .then(html => {
        this.formWrapperTarget.innerHTML = html
      })
      .catch(e => {
        console.warn(e.message)
      })
  }

  addSearchField(e) {
    e.preventDefault()

    const fieldGroupId = e.target.getAttribute("data-clip-field-group-id")

    const form = this.formWrapperTarget.querySelector("form")
    const formData = new FormData(form)
    formData.append("field_group_id", fieldGroupId)

    const csrfTokenSelected = document.querySelector("[name='csrf-token']")
    const csrfToken = csrfTokenSelected ? csrfTokenSelected.content : null

    fetch('/clip_searches/add_field', {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": csrfToken,
      },
      body: formData
    })
      .then(response => response.text())
      .then(html => {
        this.formWrapperTarget.innerHTML = html
      })
      .catch(e => {
        console.warn(e.message)
      })
  }

  removeSearchFieldGroup(e) {
    e.preventDefault()

    const target = e.target.closest(".search-field-group")
    target.remove()
  }

  removeSearchField(e) {
    e.preventDefault()

    const target = e.target.closest(".search-field")
    target.remove()
  }
}

