import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "globalSearch" ]

  connect() {
    document.addEventListener('keydown', (event) => {
      if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 'f') {
        this.globalSearchTarget.focus()
      }
    });
  }
}