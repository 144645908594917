import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "alert" ]
  static values = { url: String }


  copy(event) {
    event.preventDefault()
    navigator.clipboard.writeText(this.urlValue)
    this.alertTarget.classList.remove('d-none')
    setTimeout(()=>  {
      this.alertTarget.classList.add('d-none')
    }, 900)
  }

}