import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "submit" ]

  handleSubmit(event) {
    this.submitTarget.innerHTML = `<i class="fas fa-spinner fa-spin"></i> Loading...`
    this.submitTarget.disabled = true
  }

  handleClick(event) {
    event.target.innerHTML = `<i class="fas fa-spinner fa-spin"></i> Loading...`
    event.target.classList.add('disabled')
  }
}

