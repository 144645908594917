import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "datefield" ]

  connect() {
  }

  today(event) {
    event.preventDefault()
    const today = new Date()
    this.datefieldTarget.value = today.toISOString().split('T')[0]
  }

}