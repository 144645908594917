function getCsrfToken() {
  const element = document.head.querySelector(`meta[name="csrf-token"]`)
  if(element) {
    return element.getAttribute("content")
  }
  else {
    return null
  }
}

export default getCsrfToken
