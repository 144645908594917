import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sortByInput", "sortDirectionInput", "header"]

  connect() {
    this.currentSortColumn = this.sortByInputTarget.value
    this.currentSortDirection = this.sortDirectionInputTarget.value

    this.headerTargets.forEach(headerTarget => {
      const headerSortColumn = headerTarget.getAttribute("data-sort-column")
      const headerSortDirection = headerTarget.getAttribute("data-sort-direction") || "asc"

      if(headerSortColumn == this.currentSortColumn) {
        const sortIcon = document.createElement("i")

        if(headerSortDirection == this.currentSortDirection) {
          sortIcon.classList.add("bi", "bi-sort-alpha-down")
        }
        else {
          sortIcon.classList.add("bi", "bi-sort-alpha-up")
        }

        headerTarget.prepend(sortIcon)
      }
    })
  }

  sortBy(e) {
    e.preventDefault()

    const newSortColumn = e.target.getAttribute("data-sort-column")
    const defaultSortDirection = e.target.getAttribute("data-sort-direction") || "asc"

    if(newSortColumn == this.currentSortColumn) {
      // Same sort column, toggle direction
      const toggledSortDirection = this.currentSortDirection == "asc" ? "desc" : "asc"
      this.sortDirectionInputTarget.value = toggledSortDirection
    }
    else {
      // New sort column, default direction
      this.sortByInputTarget.value = newSortColumn
      this.sortDirectionInputTarget.value = defaultSortDirection
    }


    const form = this.sortByInputTarget.closest('form')
    form.submit()
  }
}


