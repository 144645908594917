import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
  }

  disconnect() {
    clearTimeout(this.timeout)
  }

  copy(event) {
    event.preventDefault()
    const copyText = event.target.getAttribute("data-text")
    navigator.clipboard.writeText(copyText)
    event.target.classList.add('fa-clipboard-check')
    event.target.classList.remove('fa-clipboard')

    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      event.target.classList.add('fa-clipboard')
      event.target.classList.remove('fa-clipboard-check')
    }, 1000)
  }
}

