import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "container"]


  connect() {
  }

  disconnect() {
    clearTimeout(this.timeout)
  }

  hide(event) {
    event.preventDefault()
    this.containerTarget.classList.add('d-none')

  }
}

