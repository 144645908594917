import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "bulkClipIds", 'bulkCheckbox', 'bulkEditButton' ]


  connect() {
    this.setId()
  }

  setId() {
    // this.bulkEditButtonTarget.style.display = 'none'
    const ids = this.bulkCheckboxTargets.filter(checkbox => checkbox.checked).map(checkbox => checkbox.value)
    this.bulkClipIdsTarget.value = ids.join(',')
    if ( ids.length ) {
      this.bulkEditButtonTarget.style.display = 'block'
    } 
    else {
      this.bulkEditButtonTarget.style.display = 'none'
    }
  }

  selectAll(event) {
    console.log('asdf')
    const is_checked = event.target.checked
    this.bulkCheckboxTargets.forEach(checkbox => checkbox.checked = is_checked)
    this.setId()
  }

}