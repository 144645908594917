// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import Rails from "@rails/ujs"
import * as Turbo from "@hotwired/turbo"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require("@popperjs/core")

import 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'css/site'
import 'helpers/bootstrap-dark-mode'

Rails.start()
Turbo.start()
ActiveStorage.start()

import "controllers"


document.addEventListener("turbo:load", () => {
    // Both of these are from the Bootstrap 5 docs
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
    })

    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
        return new Popover(popoverTriggerEl)
    })
})

// If we upgrade to Turbo 8 we need to verify this still works, this is a workaround found at
// https://github.com/hotwired/turbo/issues/37
// There's a lot of discussion about the 'correct' way to handle the disabling of this behavior
// For the purposes of our app if you need an action/navigation to _NOT_ reset the scroll position simply
// add the following to the clicked element:
// data: "data-turbo-preserve-scroll"
// This will remove the turbo scroll behavior until the next click on an element that does not have that same data
// attribute
function disableTurboScroll() {
  if (!window.thisWillNeverBeTrue) Turbo.navigator.currentVisit.scrolled = true
}
let scrollTop = 0

addEventListener("turbo:click", ({ target }) => {
  if (target.attributes[0].value === 'data-turbo-preserve-scroll') {
    document.addEventListener('turbo:before-render', disableTurboScroll)
  } else {
    document.removeEventListener('turbo:before-render', disableTurboScroll)
  }
})

addEventListener("turbo:load", () => {
  if (scrollTop) {
    document.scrollingElement.scrollTo(0, scrollTop)
  }

  scrollTop = 0
})