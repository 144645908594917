import { Controller } from "@hotwired/stimulus"
import TimelinesChart from 'timelines-chart'
import { addSeconds, addMilliseconds } from 'date-fns'
import noUiSlider from 'nouislider'
import 'nouislider/dist/nouislider.css'

export default class extends Controller {

  static targets = [ "audio", "transcript", "transcriptTimeline", "transcriptWord", "scrubber", "startTime", "endTime", "currentTime", "extractedText" ]
  static values = { segments: Array, excludedWords: Array, seek: Number, segmentStart: Number, segmentEnd: Number }

  connect() {
    google.charts.load('current', {packages: ['timeline']});
    if(this.hasTranscriptTimelineTarget) {
      google.charts.setOnLoadCallback(this.drawTranscriptChart)
    }
    if(this.hasTranscriptTarget) {
      this.audioTarget.addEventListener('timeupdate', this.timeUpdate)
      this.transcriptTarget.addEventListener('mouseup', this.handleTranscriptHighlighted)
    }
    this.audioTarget.addEventListener('loadedmetadata', this.setupScrubber)
    this.highlightTrimmedSegments()
    if(this.hasSeekValue) {
      this.audioTarget.currentTime = this.seekValue
    }
  }

  setupScrubber = () => {
    const audioLength = this.audioTarget.duration
    const startsAt = this.segmentStartValue || 0
    const endsAt = this.segmentEndValue || audioLength

    this.scrubber = noUiSlider.create(this.scrubberTarget, {
      start: [startsAt, endsAt],
      connect: true,
      behaviour: 'tap-drag',
      range: {
        'min': 0,
        'max': audioLength
      }
    });
    this.scrubber.on('update', this.scrubberUpdate)
  }

  scrubberUpdate = (values, handle) => {
    this.startTimeTarget.value = values[0]
    this.endTimeTarget.value = values[1]

    const currentTime = this.audioTarget.currentTime
    if(currentTime < values[0]) {
      this.audioTarget.currentTime = values[0]
    }
    else if(currentTime > values[1]) {
      this.audioTarget.currentTime = values[1]
    }

    if(this.trimmedSegmentTimeout) {
      clearTimeout(this.trimmedSegmentTimeout)
    }
    this.trimmedSegmentTimeout = setTimeout(() => {
      let trimmedText = ``

      this.transcriptTarget.querySelectorAll(".word").forEach(target => {
        const startTime = target.getAttribute("data-audio-start-time")
        const endTime = target.getAttribute("data-audio-end-time")
        if(startTime && endTime && parseFloat(startTime) >= parseFloat(values[0]) && parseFloat(endTime) <= parseFloat(values[1])) {
          target.classList.add("trimmed-segment")
          trimmedText += `${target.innerText} `
        } else {
          target.classList.remove("trimmed-segment")
        }
      })

      this.extractedTextTarget.value = trimmedText
    })
  }

  seekTo = (event) => {
    event.preventDefault()
    const timestamp = event.target.closest('a').getAttribute("data-audio-start-time")
    this.audioTarget.currentTime = timestamp
  }

  timeUpdate = (event) => {
    const timestamp = event.target.currentTime

    // Update the current time display
    this.currentTimeTarget.value = timestamp.toFixed(2)

    // Highlight the current word
    this.transcriptWordTargets.forEach(target => {
      const startTime = target.getAttribute("data-audio-start-time")
      if (timestamp >= startTime) {
        target.classList.add("played")
      } else {
        target.classList.remove("played")
      }
    })

    // Pause if within 1 second of the end time
    const endTime = this.endTimeTarget.value
    if(endTime && timestamp >= parseFloat(endTime) && timestamp <= parseFloat(endTime) + 1) {
      this.audioTarget.pause()
    }
  }

  drawTranscriptChart = () => {
    const now = new Date()
    const transcriptData = new Map()

    this.transcriptWordTargets
      .filter(target => target.classList.contains("pronunciation"))
      .forEach(target => {
        const word = target.innerText.trim().toLowerCase()
        const wordlist = transcriptData.get(word) || []

        const startTime = target.getAttribute("data-audio-start-time")
        const endTime = target.getAttribute("data-audio-end-time")
        wordlist.push([word, "", addSeconds(now, startTime), addSeconds(now, endTime)])
        transcriptData.set(word, wordlist)
    })

    const chart = new google.visualization.Timeline(this.transcriptTimelineTarget)
    const dataTable = new google.visualization.DataTable()
    dataTable.addColumn({ type: 'string', id: 'Position' });
    dataTable.addColumn({ type: 'string', id: 'Name' });
    dataTable.addColumn({ type: 'date', id: 'Start' });
    dataTable.addColumn({ type: 'date', id: 'End' });

    Array.from(transcriptData)
      .filter(elem => !this.excludedWordsValue.includes(elem[0]))
      .map(elem => elem[1])
      .sort((a, b) => b.length - a.length)
      .forEach(value => dataTable.addRows(value))

    chart.draw(dataTable);


    // Track when the user clicks on a timeline bar
    google.visualization.events.addListener(chart, 'select', (event) => {
      const selection = chart.getSelection()
      if (selection.length === 1) {
        const segment = dataTable.getValue(selection[0].row, 1)
        const startTime = dataTable.getValue(selection[0].row, 2)
        const offset = (startTime - now) / 1000
        this.audioTarget.currentTime = offset
      }
    })
  }

  highlightTrimmedSegments = () => {
    this.transcriptTarget.querySelectorAll(".word").forEach(target => target.classList.add("trimmed-segment"))
  }

  copyStartEndTimes = (event) => {
    event.preventDefault()
    const currentTime = this.currentTimeTarget.value
    const parsedCurrentTime = parseFloat(currentTime)
    if(parsedCurrentTime && !isNaN(parsedCurrentTime)) {
      this.scrubber.set([parsedCurrentTime, parsedCurrentTime + 3])
    }

  }

  onStartEndBlur = (event) => {
    const startTime = this.startTimeTarget.value
    const endTime = this.endTimeTarget.value
    this.scrubber.set([startTime, endTime])
  }

  handleTranscriptHighlighted = (event) => {
    const selection = document.getSelection()
    const range = selection.getRangeAt(0)
    const words = Array.from(range.cloneContents().querySelectorAll(".word"))
    const startTimestamp = words.map(word => word.getAttribute("data-audio-start-time"))
      .map(word => parseFloat(word))
      .filter(word => word)
      .shift()
    const endTimestamp = words.map(word => word.getAttribute("data-audio-end-time"))
      .map(word => parseFloat(word))
      .filter(word => word)
      .pop()

    if(startTimestamp && endTimestamp) {
      this.scrubber.set([startTimestamp - .01, endTimestamp])
      selection.removeAllRanges() // Clear existing selection
    }
  }


}
